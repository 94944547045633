.month_main {
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(23, 6, 85, 1) 0%,
    rgba(176, 17, 185, 1) 100%
  );
  overflow-x: hidden;
  background: rgb(155, 17, 168);
  background: radial-gradient(
    circle,
    rgba(155, 17, 168, 0.7178221630449055) 0%,
    rgba(55, 0, 63, 1) 100%
  );

  background: rgb(210, 64, 64);
  background: linear-gradient(
    125deg,
    rgba(210, 64, 64, 0.7178221630449055) 0%,
    rgba(106, 0, 90, 1) 67%
  );
}

.month_title {
  text-align: center;
  font-size: 3.5rem;
  font-family: "Uchen";
  font-family: "Bree+Serif", serif;
  color: white;
  font-weight: 900;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.month_img {
  width: 18rem;
  margin: 1rem;
  border-radius: 20px;
}

.month_text {
  color: white;
  font-size: 1.3rem;
  margin: 1.75rem 20vw;
  font-family: "Lato";
  font-family: "Bree+Serif", serif;

  font-weight: 500;
  width: 60vw;
}
.month_text_big {
  font-size: 1.5rem;
  font-weight: bold;
}

.imgslider {
  margin-bottom: 3rem;
  margin-top: 2rem;
}

.back_link {
  color: white;
  font-size: 1.2rem;
  margin: 1.75rem 15vw;
  font-family: "Lato";
  font-weight: 600;
  width: 70vw;
  font-family: "Bree+Serif", serif;

  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .month_title {
    font-size: 6.5vw;
  }
  .month_text {
    font-size: 3vw;
    width: 90vw;
    margin-left: 5vw;
  }
  .month_text_big {
    font-size: 4vw;
  }
  .month_img {
    width: 16rem;
  }
}
